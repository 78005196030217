import { useEffect, useState, useRef } from "react";
import {
  Button,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Tfoot,
  Td,
  Box,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  TableCaption,
  Textarea,
  Input,
  Center,
  Link,
} from "@chakra-ui/react";
import "draft-js/dist/Draft.css";
import ModalAnnounce from "Components/ModalAnnounce";
import Axios from "axios";
import ModalConfirmation from "Components/ModalConfirmation";
import { useHistory } from "react-router-dom";

export default function Announcement() {

  let history = useHistory();

  const messageDialogRef = useRef();

  const [header, setHeader] = useState("");
  const [subheader, setSubheader] = useState("");
  const [title, setTitle] = useState("");
  const [paragraph, setParagraph] = useState("");

  // Error or success message
  const [message, setMessage] = useState("");

  useEffect(() => {
    getAnnouncement();
  }, []);

  async function getAnnouncement() {
    await Axios.get("https://kckarmalv4.execute-api.ap-southeast-1.amazonaws.com/announcement")
      .then((res) => {
        setHeader(res.data[0].announcement);
        setSubheader(res.data[0].subtitle);
        setTitle(res.data[0].title);
        setParagraph(res.data[0].paragraph);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //when "post" button onclick need to run this function when go real
  async function sendAnnouncement(e) {
    e.preventDefault();
    let announceData = {
      announcement: header,
      subtitle: subheader,
      title,
      paragraph,
    };

    await Axios.post("https://kckarmalv4.execute-api.ap-southeast-1.amazonaws.com/announce", announceData)
      .then((res) => {
        console.log(res.data);
        setMessage(res.data.message);
        messageDialogRef.current.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Center>
      <Table w={["50%"]} variant="simple">
        <TableCaption>
          <ModalAnnounce
            header={header}
            subheader={subheader}
            title={title}
            paragraph={paragraph}
          />
          <Button onClick={sendAnnouncement} color="white" bg="#D32424" mx="10">
            Post
          </Button>
          <ModalConfirmation
            message={message}
            messageDialogRef={messageDialogRef}
          />
        </TableCaption>

        <Thead>
          <Tr>
            <Th>Type</Th>
            <Th>Input</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>Header</Td>
            <Td>
              <Input
                value={header}
                // value="Welcome to My3dVision!"
                onChange={(e) => setHeader(e.target.value)}
              />
            </Td>
          </Tr>
          <Tr>
            <Td>Sub-header</Td>
            <Td>
              <Input
                value={subheader}
                onChange={(e) => setSubheader(e.target.value)}
              />
            </Td>
          </Tr>
          <Tr>
            <Td>Title</Td>
            <Td>
              <Input value={title} onChange={(e) => setTitle(e.target.value)} />
            </Td>
          </Tr>
          <Tr>
            <Td>Paragraph</Td>
            <Td>
              <Textarea
                whiteSpace="pre-wrap"
                rows="16"
                resize="none"
                value={paragraph}
                onChange={(e) => setParagraph(e.target.value)}
              />
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Center>
  );
}
