import VisitsPerDay from "Dashboard/visitsPerDay";
import { useState } from "react";

export default function AnalyticsC() {
  const [cred, setCred] = useState({
    email: "ougdevelopmentvse@gmail.com",
    authorization: "Bearer KDnfqLJvc8oQThKcbVwCWLGSwgJzyUYAS9Uei5wz",//KNbGM4xSLMDlpnQrva994ylR0q2veaYPs-LdUFyb
    zoneTag: "fcf1026f1a2a1c4bceeef92a9cb80cce",
  });

  return (
    <>
      {/* <AgentsVisitsReport /> */}
      {/* <TopCountriesByRequests cred={cred} /> */}
      <VisitsPerDay cred={cred} />
      {/* <Tes cred={cred} /> */}
    </>
  );
}
