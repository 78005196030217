import {
  Button,
  Text,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Modal,
  useDisclosure,
  IconButton,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Tfoot,
  Td,
  Link,
  Select,
  Input,
  Textarea,
} from "@chakra-ui/react";
import { CloseIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { useState } from "react";
import Axios from "axios";

function ModalReject({ user, setList }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [reason, setReason] = useState("");

  async function rejectBook(e) {
    e.preventDefault();

    let userData = {
      email: user.email,
      booking_number: user.booking_number,
      reject_reason: reason,
    };

    await Axios.post("https://kckarmalv4.execute-api.ap-southeast-1.amazonaws.com/reject", userData)
    // await Axios.post("data.json", userData)

      .then((res) => {
        setList(res.data);
        onClose();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <Button
        fontSize="100%"
        onClick={onOpen}
        leftIcon={<CloseIcon />}
        bg="#D32424"
        variant="solid">
        Reject
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{user.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th textAlign="center" colSpan={2}>
                    Prospect's Payment Details
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Td>
                  <Tr height="7" fontWeight="bold">
                    Full Name
                  </Tr>
                  <Tr height="7" fontWeight="bold">
                    Email
                  </Tr>
                  <Tr height="7" fontWeight="bold">
                    Payment Slip
                  </Tr>
                  <Tr height="7" fontWeight="bold">
                    Booking #
                  </Tr>
                  <Tr height="7" fontWeight="bold">
                    Agent
                  </Tr>
                  <Tr height="7" fontWeight="bold">
                    Booked On
                  </Tr>
                </Td>
                <Td>
                  <Tr height="7">{user.name}</Tr>
                  <Tr height="7">{user.email}</Tr>
                  <Tr height="7">
                    <Link href={`${user.slip}`} isExternal>
                      Download Slip <ExternalLinkIcon mx="2px" />
                    </Link>
                  </Tr>
                  <Tr height="7">{user.booking_number}</Tr>
                  <Tr height="7">{user.agent_name}</Tr>
                  <Tr height="7">{user.date}</Tr>
                </Td>
              </Tbody>
            </Table>
            <hr></hr>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th textAlign="center" colSpan={2}>
                    Rejection Reason
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Td>
                  <Tr
                    d="flex"
                    alignItems="center"
                    height="12"
                    fontWeight="bold"
                    colSpan={2}>
                    <Textarea
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                      resize="none"
                      placeholder="Reason for rejection."
                    />
                  </Tr>
                </Td>
              </Tbody>
            </Table>
          </ModalBody>

          <ModalFooter d="flex" justifyContent="center" alignItems="center">
            <Button
              onClick={(e) => rejectBook(e)}
              leftIcon="&#215;"
              bg="#D32424"
              variant="solid">
              Finalize Rejection
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
{
}
export default ModalReject;
