import Axios from "axios";
import {
  Button,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Tfoot,
  Td,
  Box,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Input,
  Center,
  HStack,
  Link,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import {
  ChevronDownIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  CheckIcon,
  DeleteIcon,
  EmailIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ExternalLinkIcon,
} from "@chakra-ui/icons";
import Pagination from "react-js-pagination";
import ArrayToExcelMain from "Components/ArrayToExcelMain";
import ArrayToExcelMainRegistered from "Components/ExcelRegistered/ArrayToExcelMainRegistered";

export default function Registered() {
  const [registered, setRegistered] = useState([]);

  // Filtering
  const [name, setName] = useState(true);
  const [byAgency, setByAgency] = useState(false);
  const [email, setEmail] = useState(false);
  const [filterName, setFilterName] = useState("Name");
  const [search, setSearch] = useState("");
  const [filteredDatas, setFilteredDatas] = useState([]);

  const [byAll, setByAll] = useState(true);
  const [byApproved, setByApproved] = useState(false);
  const [byPending, setByPending] = useState(false);
  const [byDescending, setByDescending] = useState(false);
  const [byAscending, setByAscending] = useState(false);
  const [status, setStatus] = useState("All");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    // await Axios.get(""https://api.my3dvision.com/interested-list")
    await Axios.get("https://kckarmalv4.execute-api.ap-southeast-1.amazonaws.com/interested-list")
    // await Axios.post("data.json")

      .then((res) => {
        let prospects = res.data;

        setRegistered(prospects);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (byAll) {
      setFilteredDatas(
        registered.filter((data) => {
          let filtered;
          if (name) {
            filtered = data.name
              .toLowerCase()
              .includes(search.toLowerCase());
          } else if (byAgency) {
            filtered = "Isola KLCC"
              .toLowerCase()
              .includes(search.toLowerCase());
          } else if (email) {
            filtered = data.email.toLowerCase().includes(search.toLowerCase());
          }
          return filtered;
        })
      );
    } else if (byApproved) {
      setFilteredDatas(
        registered.filter((data) => {
          let filtered;
          if (data.status === 1) {
            if (name) {
              filtered = data.name
                .toLowerCase()
                .includes(search.toLowerCase());
            } else if (byAgency) {
              filtered = "Isola KLCC"
                .toLowerCase()
                .includes(search.toLowerCase());
            } else if (email) {
              filtered = data.email
                .toLowerCase()
                .includes(search.toLowerCase());
            }
          }
          return filtered;
        })
      );
    } else if (byPending) {
      setFilteredDatas(
        registered.filter((data) => {
          let filtered;
          if (data.status === 0) {
            if (name) {
              filtered = data.name
                .toLowerCase()
                .includes(search.toLowerCase());
            } else if (byAgency) {
              filtered = "Isola KLCC"
                .toLowerCase()
                .includes(search.toLowerCase());
            } else if (email) {
              filtered = data.email
                .toLowerCase()
                .includes(search.toLowerCase());
            }
          }
          return filtered;
        })
      );
    }
  }, [
    search,
    registered,
    byPending,
    byAll,
    byApproved,
    byDescending,
    byAscending,
    byAgency,
    email,
    name,
  ]);

  let prospect_rows = filteredDatas.map((user, key) => {
    // let projects = JSON.parse(user.interested_projects);
    let projects = ["Isola KLCC"];

    let projects_rows = projects.map((project, key) => {
      return (
        <Text p={1} key={key}>
          {project}
        </Text>
      );
    });

    return (
      <Tr key={key}>
        <Td className="key">{key + 1}.</Td>
        <Td p={0}>
          <Box d="flex" alignItems="center" justifyContent="space-between">
            {user.name}
            <br />
            {user.email}
          </Box>
        </Td>

        <Td>{user.phone}</Td>

        <Td textAlign="justify">{projects_rows}</Td>

        <Td>
          {user.registered}
          <br />
          {user.date}
        </Td>
      </Tr>
    );
  });

  return (
    <Center>
      <Box w={["100%", "90%", "80%", "75%"]}>
        <Table>
          <Thead>
            <Tr>
              <Th>No.</Th>
              <Th p={0}>
                <p>Name</p>
              </Th>

              <Th>
                <Text>Mobile</Text>
              </Th>


              <Th w={["100%", "100%", "30%", "30%"]}>
                <Center>
                  <Input
                    type="text"
                    placeholder={`Search By ${filterName}`}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <Menu>
                    <MenuButton isLazy>
                      <Button>{filterName}</Button>
                    </MenuButton>
                    <MenuList>
                      <MenuItem
                        onClick={(e) => {
                          setByAgency(false);
                          setName(true);
                          setEmail(false);
                          setFilterName("Name");
                        }}>
                        Name
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => {
                          setByAgency(true);
                          setName(false);
                          setEmail(false);
                          setFilterName("Project Name");
                        }}>
                        Project Name
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => {
                          setByAgency(false);
                          setName(false);
                          setEmail(true);
                          setFilterName("Email");
                        }}>
                        Email
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Center>
              </Th>

              <Th>
                <HStack spacing={0}>
                <Box>
                  <Text>Date</Text>
                </Box>
                {registered.length > 0 ? (
                  <ArrayToExcelMainRegistered registered={registered}/>
                  ) : null}
                </HStack>
              </Th>
            </Tr>
            <br />
          </Thead>
          <Tfoot>
            <Tr>
              <Td></Td>
            </Tr>
          </Tfoot>

          <Tbody>
            {registered.length === 0 ? (
              <Td colSpan="9"> All caught up! </Td>
            ) : (
              prospect_rows
            )}
          </Tbody>
        </Table>
      </Box>
    </Center>
  );
}
