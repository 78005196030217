import React, { useState, useEffect } from "react";
import { addDays } from "date-fns";
import CustomDatePicker from "./datepicker";
import { queryReport } from "./queryReport";
import {
  ChartTitle,
  ReportWrapper,
  Subtitle,
  DatepickerRow,
  StyledTable,
} from "./styles";
import { Link } from "react-router-dom";

import {
  Button,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Box,
  Text,
  Center,
} from "@chakra-ui/react";
import MainGalleryViewsPerDay from "./mainGalleryViewsPerDay";

const TopPagesByProjectVisits = (props) => {
  const [reportData, setReportData] = useState([]);
  const [startDate, setStartDate] = useState(addDays(new Date(), -10));
  const [endDate, setEndDate] = useState(new Date());
  const [totalPages, setTotalPages] = useState(0);

  const displayResults = (response) => {
    // let obj = {
    //   dimensions: ["/Main.html?v=1612330819813"],
    //   metrics: [{values: ["500"]}]
    // }
    const queryResult = response.result.reports[0].data.rows;
    setTotalPages(queryResult.length);
    const total = response.result.reports[0].data.totals[0].values[0];
    let newReportData = [];

    queryResult.forEach((row, idx) => {
      const path = row.dimensions[0];

      function PageName(pathName) {
        let first = pathName.split(".");
        return first[0].replace(/[^\w\s!?]/g, "");
      }
      let tempObj = {
        path: PageName(path).replace(/_/g, " "),
        views: row.metrics[0].values[0],
        perc: `${parseFloat((row.metrics[0].values[0] / total) * 100).toFixed(
          1
        )}%`,
      };
      newReportData.push(tempObj);
    });

    let array = newReportData.filter((row) => {
      return row.path == "Main";
    });

    let Maple = newReportData.filter((row) => {
      return row.path == "maple tracking";
    });

    let Waltz = newReportData.filter((row) => {
      return row.path == "waltz tracking";
    });

    let Renai = newReportData.filter((row) => {
      return row.path == "renai tracking";
    });

    let Sapphire = newReportData.filter((row) => {
      return row.path == "sapphire tracking";
    });

    let Azure = newReportData.filter((row) => {
      return row.path == "azure tracking";
    });

    let Medini = newReportData.filter((row) => {
      return row.path == "medini tracking";
    });

    let Paradigm = newReportData.filter((row) => {
      return row.path == "paradigm tracking";
    });

    let Impiria = newReportData.filter((row) => {
      return row.path == "impiria tracking";
    });

    function addUp(array) {
      let views = [];

      array.forEach((row) => {
        return views.push(parseInt(row.views));
      });

      const reducer = (acc, current) => acc + current;

      if (views.length > 0) {
        return views.reduce(reducer);
      } else return 0;
    }

    function addUpP(array) {
      let views = [];
      array.forEach((row) => {
        return views.push(parseInt(row.perc));
      });

      const reducer = (acc, current) => acc + current;
      if (views.length > 0) {
        return `${views.reduce(reducer)}%`;
      } else return "0%";
    }

    let maple = {
      path: "The Maple Residences",
      views: addUp(Maple),
      perc: addUpP(Maple),
    };

    let renai = {
      path: "Renai Jelutong Residences",
      views: addUp(Renai),
      perc: addUpP(Renai),
    };

    let sapphire = {
      path: "Sapphire Paradigm Residences",
      views: addUp(Sapphire),
      perc: addUpP(Sapphire),
    };

    let azure = {
      path: "Azure Residences",
      views: addUp(Azure),
      perc: addUpP(Azure),
    };

    let paradigm = {
      path: "Paradigm Residences",
      views: addUp(Paradigm),
      perc: addUpP(Paradigm),
    };

    let waltz = {
      path: "Waltz Residences",
      views: addUp(Waltz),
      perc: addUpP(Waltz),
    };

    let medini = {
      path: "Medini Signature",
      views: addUp(Medini),
      perc: addUpP(Medini),
    };

    let impiria = {
      path: "Impiria Residensi",
      views: addUp(Impiria),
      perc: addUpP(Impiria),
    };

    let main = {
      path: "Main Gallery ",
      views: addUp(array),
      perc: addUpP(array),
    };

    let consolidatedData = [];

    consolidatedData.push(
      main,
      maple,
      waltz,
      sapphire,
      paradigm,
      medini,
      renai,
      impiria,
      azure
    );

    consolidatedData.sort(function (a, b) {
      return b.views - a.views;
    });

    // newReportData.unshift(main)

      setReportData(consolidatedData);

    // setReportData(newReportData.filter(row => {
    //   return row.path
    //   return row.path !== 'Main'&& row.path !== '' && row.path !== '?gtm_debugx' && row.path !== 'analytics'
    // }));
  };

  function PageNames(row) {
    return row.path;
  }

  useEffect(() => {
    const request = {
      viewID: props.viewID,
      startDate,
      endDate,
      metrics: "ga:pageviews",
      dimensions: ["ga:pagePath"],
      orderBy: {
        fieldName: "ga:pageViews",
        order: "DESCENDING",
      },
      filter: "ga:pagePath!@localhost/",
    };
    setTimeout(
      () =>
        queryReport(request)
          .then((resp) => displayResults(resp))
          .catch((error) => console.error(error)),
      1000
    );
  }, [startDate, endDate]);

  return (
    <Box pt="80px" pb={0} mb={0}>
      <ReportWrapper>
        <ChartTitle>Top Pages by Project Visits</ChartTitle>
        <DatepickerRow>
          <CustomDatePicker
            placeholder={"Start date"}
            date={startDate}
            handleDateChange={(date) => setStartDate(date)}
          />

          <CustomDatePicker
            placeholder={"End date"}
            date={endDate}
            handleDateChange={(date) => setEndDate(date)}
          />
        </DatepickerRow>
        {reportData.length && (
          <Table className="pageviewReport">
            <Thead>
              <Tr>
                <Th color="white">Page Visits</Th>
                <Th className="views" color="white">
                  View
                </Th>
                {/* <th>%</th> */}
              </Tr>
            </Thead>
            <Tbody>
              {reportData.map((row, id) => (
                <Tr key={id}>
                  <Td className="left-align">{PageNames(row)}</Td>
                  <Td className="views">{row.views}</Td>
                  {/* <td>{row.perc}</td> */}
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
        <MainGalleryViewsPerDay
          metric={"ga:users"}
          title={"Visits"}
          viewID={props.viewID}
          startDate={startDate}
          endDate={endDate}
        />
      </ReportWrapper>
    </Box>
  );
};

export default TopPagesByProjectVisits;
