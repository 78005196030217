import React, { useState, useEffect } from "react";
import { Line, Bar } from "react-chartjs-2";
import { addDays, format } from "date-fns";
import {
  ChartWrapper,
  ReportWrapper,
  ChartTitle,
  Subtitle,
  DatepickerRow,
} from "./styles";
import CustomDatePicker from "./datepicker";
import Axios from "axios";
import {
  Button,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Box,
  Text,
  Center,
} from "@chakra-ui/react";

const VisitsPerDay = ({ cred }) => {
  const INITIAL_STATE = {
    labels: [],
    values: [],
  };
  const [reportData, setReportData] = useState(INITIAL_STATE);
  const [startDate, setStartDate] = useState(addDays(new Date(), -10));
  const [endDate, setEndDate] = useState(new Date());
  const [average, setAverage] = useState(0);
  const [total, setTotal] = useState(0);
  const [tableVisits, setTableVisits] = useState([]);

  const data = {
    labels: reportData.labels,
    datasets: [
      {
        label: `Visits per day`,
        fill: false,
        lineTension: 0.3,
        borderColor: "#D32424",
        // backgroundColor: "#D32424",
        pointBorderWidth: 3,
        pointHoverBackgroundColor: "#D32424",
        pointHoverBorderColor: "#D32424",
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        borderWidth: 1, // Specify bar border width
        pointHitRadius: 10,
        data: reportData.values,
      },
    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            suggestedMin: 0,
            fontColor: "white",
            fontSize: 15,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            autoSkip: true,
            maxTicksLimit: 7,
            maxRotation: 0,
            minRotation: 0,
            fontColor: "white",
            fontSize: 15,
          },
        },
      ],
    },
    maintainAspectRatio: false,
    legend: {
      display: true,
      labels: {
        fontColor: "white",
        fontSize: 18,
      },
    },
    plugins: {
      datalabels: {
        font: {
          size: 0,
        },
      },
    },
  };

  useEffect(async () => {
    await fetchAPI(
      "https://kckarmalv4.execute-api.ap-southeast-1.amazonaws.com/analytics",
      cred.email,
      cred.authorization,
      cred.zoneTag
    );
  }, [startDate, endDate]);

  function convertDate(date) {
    let string = JSON.stringify(date);
    let month = string.split("-");
    let day = month[2].split("T");

    let fullDate =
      month[0].replace(/[^0-9]/g, "") + "-" + month[1] + "-" + day[0];

    return JSON.stringify(fullDate);
  }

  function fetchAPI(url, email, authorization, zoneTag) {
    const headers = {
      authorization,
      email,
    };

    Axios.post(
      url,
      {
        query: `query {
        viewer {
          zones(filter: {zoneTag: ${zoneTag}}) {
            httpRequests1dGroups(
              filter: {
                date_geq: ${convertDate(startDate)},
                date_leq: ${convertDate(endDate)}
            },
            limit: 365,
              orderBy: [date_ASC]
            )
            {
              uniq {
                uniques
              }
              sum{
                pageViews
                requests
              }
              dimensions {
                date
              }
            }
          }
        }
      }`,
      },
      { headers }
    ).then(function (response) {
      displayResults(response);
    });
  }

  const displayResults = (response) => {
    const queryResult = response.data.data.viewer.zones[0].httpRequests1dGroups;

    let labels = [];
    let values = [];

    queryResult.map((row) => {
      let arrDate = row.dimensions.date.split("-");
      let year = parseInt(arrDate[0]);
      let month = parseInt(arrDate[1]) - 1;
      let day = parseInt(arrDate[2]);

      let formattedDate = format(
        new Date(year, month, day),
        "MMM. d, yyyy (eeeeee)"
      );

      return labels.push(formattedDate);
    });

    queryResult.map((row) => {
      return values.push(row.sum.pageViews);
    });

    setReportData({
      ...reportData,
      labels,
      values,
    });

    function addUp(array) {
      let views = [];

      array.forEach((each) => {
        return views.push(each.sum.pageViews);
      });
      const reducer = (acc, current) => acc + current;
      return views.reduce(reducer);
    }

    let total1 = addUp(queryResult);
    setAverage(parseInt(total1 / queryResult.length));
    setTotal(total1);
  };

  useEffect(() => {
    let arr = [];

    for (let i = 0; i < reportData.labels.length; i++) {
      let obj = {
        labels: reportData.labels[i],
        values: reportData.values[i],
      };

      arr.push(obj);
    }

    setTableVisits(arr);
  }, [reportData]);

  return (
    <Box py="80px" borderBottom="1px solid" mb={5}>
      <ReportWrapper>
        <ChartTitle>{`Visits per day (Table)`}</ChartTitle>
        <Subtitle d="flex" flexDir="row">
          <Button
            fontSize="21px"
            p={5}
            m={5}
            bg="#D32424">{`Average - ${average}`}</Button>

          <Button m={5} fontSize="21px" p={5} bg="#D32424">
            {`Total - ${total}`}
          </Button>
        </Subtitle>
        <DatepickerRow>
          <CustomDatePicker
            placeholder={"Start date"}
            date={startDate}
            handleDateChange={(date) => setStartDate(date)}
          />
          <CustomDatePicker
            placeholder={"End date"}
            date={endDate}
            handleDateChange={(date) => setEndDate(date)}
          />
        </DatepickerRow>
        {tableVisits.length && (
          <Center>
            <Center w="800px">
              <Box className="table-wrapper">
                <Table className="pageviewReport">
                  <Thead>
                    <Tr>
                      <Th
                        position="sticky"
                        top="0"
                        textAlign="center"
                        color="white">
                        Date
                      </Th>
                      <Th
                        position="sticky"
                        top="0"
                        className="views"
                        color="white">
                        Visits
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {tableVisits.map((row, id) => (
                      <Tr key={id}>
                        <Td className="views">{row.labels}</Td>
                        <Td className="views">{row.values}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </Center>
          </Center>
        )}

        <Box pt="80px">
          <ChartTitle>{`Visits per day  (Graph)`}</ChartTitle>

          {reportData && (
            <ChartWrapper>
              <Line data={data} options={options} width={100} height={250} />
            </ChartWrapper>
          )}
        </Box>
      </ReportWrapper>
    </Box>
  );
};

export default VisitsPerDay;
