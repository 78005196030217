import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Box,
  Center,
  Image,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import Logo from "assets/my3dvision_logo.png";

export default function ModalAnnounce({ header, subheader, title, paragraph }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button mx="10" onClick={onOpen}>
        Preview
      </Button>

      <Modal size="4xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent width="100%">
          <ModalHeader>Preview Announcement</ModalHeader>
          <ModalCloseButton />
          <ModalBody h="100%" w="100%">
            <Box
              overflowY="scroll"
              p="5"
              px="16"
              d="flex"
              flexDir="column"
              borderRadius="md"
              bg="#2D2829"
              w="100%"
              justifyContent="center"
              alignItems="center"
              h="80%">
              <Center flexDir="column" pb="10">
                <Image w="60%" src={Logo} alt="My3dvision" />
                <Text color="#ffe36c" fontSize="1.3rem" fontWeight="bold">
                  {header}
                  {/* Welcome to My3dVision! */}
                </Text>
                <Text color="#ffe36c">{subheader}</Text>
              </Center>

              <Text
                color="#ffe36c"
                fontWeight="bold"
                textAlign="left"
                alignSelf="start">
                {title}
              </Text>

              <Text color="#ffe36c" whiteSpace="pre-wrap" textAlign="justify">
                {paragraph}
              </Text>

              <Text color="#ffe36c" textAlign="end" alignSelf="flex-end">
                -My3dVision Sdn Bhd
              </Text>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
