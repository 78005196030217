import React, { useState } from "react";
import MainGalleryViewsPerDay from "./mainGalleryViewsPerDay";
import CountriesReport from "./countriesReport";
import TopPagesByProjectVisits from "./topPagesByProjectVisits";
import TopSourcesOfVisits from "./topSourcesOfVisits";
import BrowsersReport from "./browsersReport";
import DevicesReport from "./devicesReport";
import { LastRow } from "./styles";
import InputField from "../Components/input";
import { HashLink } from "react-router-hash-link";
import TopCountriesByRequests from "./topCountriesByRequests";
import { Button, Box, Center } from "@chakra-ui/react";
import AgentsVisitsReport from "./agentsVisitsReport";
import AnalyticsC from "../routes/AnalyticsC";
import Pagination from "react-js-pagination";
import TopSourcesPieChart from "./topSourcesPieChart";

const DashBoard = () => {
  const viewID = "249064016"; //249064016
  const cred = {
    // email: "1pitrees@gmail.com",
    // authorization: "Bearer KNbGM4xSLMDlpnQrva994ylR0q2veaYPs-LdUFyb",//KNbGM4xSLMDlpnQrva994ylR0q2veaYPs-LdUFyb
    // zoneTag: "2f8921372ec5cd9bb3fe86756387df40",//2f8921372ec5cd9bb3fe86756387df40
    email: "ougdevelopmentvse@gmail.com",
    authorization: "Bearer KDnfqLJvc8oQThKcbVwCWLGSwgJzyUYAS9Uei5wz",//KNbGM4xSLMDlpnQrva994ylR0q2veaYPs-LdUFyb
    zoneTag: "fcf1026f1a2a1c4bceeef92a9cb80cce",
  };

  return (
    <>
      <Center className="hash-cont">
        <Button marginInline={2}>
          <HashLink smooth to="/analytics#visits-per-day">
            Visits
          </HashLink>
        </Button>
        <Button marginInline={2}>
          <HashLink smooth to="/analytics#top-pages">
            Page Views
          </HashLink>
        </Button>

        <Button marginInline={2}>
          <HashLink smooth to="/analytics#top-countries">
            Countries
          </HashLink>
        </Button>
        <Button marginInline={2}>
          <HashLink smooth to="/analytics#top-sources">
            Sources
          </HashLink>
        </Button>
        <Button marginInline={2}>
          <HashLink smooth to="/analytics#browser-devices">
            Devices
          </HashLink>
        </Button>
      </Center>

      <section id="visits-per-day">
        <AnalyticsC />
      </section>

      <section id="top-pages">
        <TopPagesByProjectVisits
          metric={"ga:users"}
          title={"Visits"}
          viewID={viewID}
        />
        {/* <MainGalleryViewsPerDay
          metric={"ga:users"}
          title={"Visits"}
          viewID={viewID}
        /> */}
      </section>

      <section id="top-countries">
        <TopCountriesByRequests cred={cred} />
        {/* <CountriesReport viewID={viewID} /> */}
      </section>
      <section id="top-sources">
        <TopSourcesPieChart viewID={viewID} />
      </section>
      <section id="browser-devices">
        <LastRow>
          {/* <BrowsersReport viewID={viewID} /> */}
          <DevicesReport viewID={viewID} />
        </LastRow>
      </section>
    </>
  );
};

export default DashBoard;
