import styled from "styled-components";

export const ChartWrapper = styled.div`
  width: 80vw;
  margin: 0 auto;
  color: white;
`;

export const PieChartWrapper = styled.div`
  width: 48vw;
  margin: 0 auto;
  color: white;
`;

export const StyledTable = styled.table`
  width: 80%;
  margin: 0 auto;
  border-collapse: collapse;
  color: white;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    color: white;
  }

  th {
    background: black;
    color: white;
  }

  .left-align {
    text-align: left;
    color: white;
  }
`;

export const colors = [
  "#fcba03",
  "#fa8c5c",
  "#9fc934",
  "#60d17e",
  "#45afed",
  "#7c5cdb",
  "#ce5cdb",
  "#db5c97",
];

export const ChartTitle = styled.h2`
  color: "white";
  font-size: 1.7rem;
`;

export const Subtitle = styled.h3`
  color: "white";
  font-size: 1rem;
  padding-bottom: 20px;
  font-weight: normal;
`;

export const ReportWrapper = styled.div`
  color: white;
`;

export const LastRow = styled.div`
  padding: 20px 0;
  margin-bottom: 150px;
`;

export const DatepickerRow = styled.div`
  width: 60vw;
  display: flex;
  justify-content: space-evenly;
  margin: 0 auto;
`;

export const DatepickerWrapper = styled.div`
  color: black;
  font-weight: 500;
  margin-top: 30px;
  z-index: 2;
  .picker {
    color: "black";
    width: fit-content;
    border-color: #a2c1f2;
    border-radius: 5px;
    background-color: white;
    text-align: center;
    line-height: 20px;
    font-size: 1rem;
    margin-bottom: 20px;
    cursor: pointer;
  }
`;

export const DatepickerLabel = styled.label`
  color: white;
  padding-right: 5px;
  size: "24px";
`;
