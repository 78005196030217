import Dashboard from "Dashboard/dashboard";

export default function Analytics() {
  return (
    <div className="dashboard">
      <h1 style={{ fontSize: "24px" }}>Analytics</h1>
      <Dashboard />
    </div>
  );
}
