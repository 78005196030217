import React, { useState, useEffect } from "react";
import { addDays } from "date-fns";
import CustomDatePicker from "./datepicker";
import {
  ChartTitle,
  ReportWrapper,
  Subtitle,
  DatepickerRow,
  StyledTable,
} from "./styles";
import Axios from "axios";
import lookup from "country-code-lookup";
import {
  Button,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Box,
  Text,
  Center,
} from "@chakra-ui/react";

export default function TopCountriesByRequests({ cred }) {
  const [reportData, setReportData] = useState([]);
  const [startDate, setStartDate] = useState(addDays(new Date(), -10));
  const [endDate, setEndDate] = useState(new Date());

  useEffect(async () => {
    await fetchAPI(
      "https://kckarmalv4.execute-api.ap-southeast-1.amazonaws.com/analytics",
      cred.email,
      cred.authorization,
      cred.zoneTag
    );
  }, [startDate, endDate]);

  function convertDate(date) {
    let string = JSON.stringify(date);
    let month = string.split("-");
    let day = month[2].split("T");

    let fullDate =
      month[0].replace(/[^0-9]/g, "") + "-" + month[1] + "-" + day[0];

    return JSON.stringify(fullDate);
  }

  function fetchAPI(url, email, authorization, zoneTag) {
    const headers = {
      authorization,
      email,
    };

    Axios.post(
      url,
      {
        query: `query {
        viewer {
          zones(filter: {zoneTag: ${zoneTag}}) {
            httpRequests1dGroups(
              filter: {
                date_geq: ${convertDate(startDate)},
                date_leq: ${convertDate(endDate)}
            },
            limit: 365,
              orderBy: [date_ASC]
            )
            {
              sum{
                  countryMap {
                      clientCountryName
                      requests
                  }
              }
              dimensions {
                date
              }
            }
          }
        }
      }`,
      },
      { headers }
    ).then(function (response) {
      displayResults(response);
    });
  }

  const displayResults = (response) => {
    const queryResult = response.data.data.viewer.zones[0].httpRequests1dGroups;

    let countries = [];
    let countriesFiltered = [];

    queryResult.forEach((each) => {
      countries.push(each.sum.countryMap);
    });

    countriesFiltered = countries[0];

    for (let i = 1; i < countries.length; i++) {
      let day = countries[i];
      day.forEach((country) => {
        for (let j = 0; j < countriesFiltered.length; j++) {
          if (
            countriesFiltered[j].clientCountryName == country.clientCountryName
          ) {
            countriesFiltered[j].requests += country.requests;
          }
        }

        if (!checkName(country)) {
          countriesFiltered.push(country);
        }
      });
    }

    function checkName(country) {
      // Test if this country is in countriesFiltered or not
      let s = false;
      for (let i = 0; i < countriesFiltered.length; i++) {
        if (
          countriesFiltered[i].clientCountryName == country.clientCountryName
        ) {
          s = true;
          break;
        }
      }
      return s;
    }

    setReportData(
      countriesFiltered.sort((a, b) => {
        return b.requests - a.requests;
      })
    );
  };

  function CountryName(name) {
    let full;

    if (name == "T1") {
      return (full = lookup.byInternet("TW").country);
    } else if (name == "UNKNOWN") {
      return (full = "Others");
    } else if (name == "GB") {
      return (full = lookup.byInternet("UK").country);
    } else if (lookup.byInternet(name) == null) {
      return name;
    } else return (full = lookup.byInternet(name).country);
  }

  return (
    <Center py="32" borderBottom="1px solid">
      <ReportWrapper>
        <ChartTitle>
          Top countries by requests (activities in Main Gallery)
        </ChartTitle>
        <DatepickerRow>
          <CustomDatePicker
            placeholder={"Start date"}
            date={startDate}
            handleDateChange={(date) => setStartDate(date)}
          />
          <CustomDatePicker
            placeholder={"End date"}
            date={endDate}
            handleDateChange={(date) => setEndDate(date)}
          />
        </DatepickerRow>
        {reportData.length && (
          <Center>
            <Center w="800px">
              <Box className="table-wrapper">
                <Table className="pageviewReport">
                  <Thead>
                    <Tr>
                      <Th color="white">Country</Th>
                      <Th color="white" className="views">
                        Requests
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {reportData.map((row, id) => (
                      <Tr key={id}>
                        <Td className="left-align">
                          {CountryName(row.clientCountryName)}
                        </Td>
                        <Td className="views">
                          {row.requests
                            .toString()
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </Center>
          </Center>
        )}
      </ReportWrapper>
    </Center>
  );
}
